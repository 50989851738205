import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import useCards from "../utils/useCards";
import { StaticImage } from "gatsby-plugin-image";
import { CardCabin } from "../components/Cards";

const CardPreview = () => {
  const data = useCards();
  console.log(data);
  return (
    <Layout>
      <Seo title="Cabañas" />
      <h1 className="uppercase title text-center pb-12">Cabañas Vero</h1>
      <div className="grid lg:grid-cols-2 place-items-start gap-8 ">
        <div className="paragraph space-y-4 max-w-lg text-base px-2 lg:px-0">
          <p>
            Estamos ubicados sobre la playa de Barra de Nexpa, un paraíso del
            Pacífico Mexicano, a metros de una de las olas más codiciadas por
            los surfistas de todo el mundo.{" "}
          </p>

          <p>
            Nuestras cabañas con vista al mar, atraen no solo a surfistas, sino
            a aquellos que quieren disfrutar de unas vacaciones en la Costa
            Michoacana, relajandose en las hamacas o tomando cervezas en el
            atardecer.{" "}
          </p>

          <p>
            En Cabañas Vero tenemos un Restaurante disponible donde puedes comer
            unos ricos antojitos de la gastronomía local y una tienda de
            abarrotes con todo lo que necesitas por si prefieres cocinar tú.
          </p>

          <p>
            Nuestro predio cuenta con alberca y espacio de recreación, para
            disfrutar con familia o amigos.{" "}
          </p>

          <p>Te esperamos! </p>
          <p className="paragraph font-bold uppercase pt-4">Comodidades</p>
          <ul className="flex flex-col pl-2 pb-4">
            <li>- Alberca</li>
            <li>- Wi Fi</li>
            <li>- Estacionamiento</li>
            <li>- Se admiten mascotas</li>
            <li>- Propiedad frente a la playa</li>
            <li>- Restaurante</li>
          </ul>
          <div className="lg:flex gap-4 item-center justify-center lg:justify-start hidden">
            <Link to="/card/#cabins">
              <button className="button-primary">Ver Las Cabañas</button>
            </Link>
            <Link to="/contact">
              <button className="button-primary">Contactanos</button>
            </Link>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-4 w-full hidden">
          <StaticImage
            height={420}
            src="../images/Cabins/cabañas1.jpg"
            placeholder="tracedSVG"
            alt="entrance image"
          />
          <StaticImage
            height={420}
            src="../images/Cabins/cabañas2.jpg"
            placeholder="tracedSVG"
            alt="entrance image"
          />
          <StaticImage
            height={420}
            src="../images/Cabins/cabañas3.jpg"
            placeholder="tracedSVG"
            alt="entrance image"
          />
          <StaticImage
            height={420}
            src="../images/Cabins/cabañas4.jpg"
            placeholder="tracedSVG"
            alt="entrance image"
          />
        </div>
      </div>

      <div id="cabins" className="flex flex-col gap-16 pt-12 lg:pt-16 pb-8">
        <h2 className="title uppercase text-center">Conoce nuestras cabañas</h2>
        <div className="grid lg:grid-cols-3 place-items-center gap-6 mx-auto ">
          {data.map(
            (i) =>
              i.slug === "cabana-1" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-2" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map((i) =>
            i.slug === null
              ? null
              : i.slug === "cabana-3" && (
                  <Link to={`${i.slug}`} className=" animation">
                    <CardCabin
                      image={i.imageCard}
                      slug={i.slug}
                      title={i.title}
                      personas={i.personas}
                      data={i.tags}
                    />
                  </Link>
                )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-4" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-5" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-6" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-7" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "cabana-8" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "habitacion-simple" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
          {data.map(
            (i) =>
              i.slug === "habitacion-doble" && (
                <Link to={`${i.slug}`} className=" animation">
                  <CardCabin
                    image={i.imageCard}
                    slug={i.slug}
                    title={i.title}
                    personas={i.personas}
                    data={i.tags}
                  />
                </Link>
              )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CardPreview;
